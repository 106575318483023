.home-page-wrapper {

  .hero-slider-wrapper {
    .slick-list {
      .hero-image {
        width: 100%;
        object-fit: cover;
        max-height: 450px;
        height: 450px;
      }
    }
    .hero-mobile {
      display: block;

      img {
        max-height: 300px;
      }
    }
  }

  .category-slider {
    @media only screen and (min-width: 876px){
      padding: 35px 25px;
      .slide-box-wrapper {
        padding: 10px;
      }
    }

    .slide-box-wrapper {
      .slide-box {
        background: #fff;
        border-radius: 10px;
        padding: 10px;

        .slide-cat-image {
          img {
            height: 180px;
            width: 100%;
            object-fit: contain;
            object-position: left;
            border-radius: 10px;
          }
        }

        .slide-cat-info {
          padding: 10px;

          h3 {
            font-size: 16px;
            font-weight: bold;
          }

          .card-desc {
            font-size: 14px;
          }

          .slide-link-cat {
            display: flex;
            align-items: center;
            //padding-bottom: 10px;
            span {
              font-weight: bold;
              padding-right: 10px;
            }

            img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  .sell-section {
    background-color: var(--color-bg-seconary);
    padding: 20px 0;
    margin: 50px 0;
    border-radius: 10px;
    @media only screen and (max-width: 876px){
      margin: 25px 0;
      .label-info {
        padding-left: 20px !important;
        &:before {
          margin-left: 7px;
        }
      }
      .button-add-ad-wrapper {
        .thx-btn {
          justify-content: center !important;
        }
      }
    }
    .label-info {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 60px;
        height: 100%;
        left: 0;
        background-image: url("../../assets/icons/sell.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .info {
        border-left: solid 4px var(--color-primary);
        margin-left: 60px;
        padding-left: 10px;
        .label {
          p {
            font-size: 14px;
          }
        }
        .title-sell {
          p {
            font-size: 30px;
            line-height: 1.1;
            font-weight: bold;
          }
        }
      }
    }
    .button-add-ad-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .tabs-home {
    .ant-tabs-nav {
      &:before {
        border-bottom: 2px solid #ddd;
      }
      .ant-tabs-nav-wrap {
        width: 100%;
        .ant-tabs-nav-list {
          width: 100%;
          @media only screen and (max-width: 876px){
            flex-direction: column;
            .ant-tabs-ink-bar {
              display: none;
            }
          }
          .ant-tabs-tab {
            flex: 1;
            width: 100%;
            margin: 0;
            transition: .3s;
            .ant-tabs-tab-btn {
              width: 100%;
              text-align: center;
              font-size: 20px;
            }
          }
          .ant-tabs-tab-active {
            background-color: var(--color-secondary);
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }
          .ant-tabs-ink-bar {
            width: 50%;
          }
        }
      }
      .ant-tabs-nav-operations {
        display: none !important;
      }

    }
    .ant-tabs-content-holder {
      .tab-title-wrapper {
        padding-top: 20px;
        h3 {
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          text-transform: uppercase;
          @media only screen and (max-width: 876px){
            font-size: 22px;
            padding: 0 10px;
          }
        }
      }

      .list-wrapper-custom {
        padding-top: 10px;
        list-style: none;
        .ant-col {
          .card-wrapper {
            padding: 15px;
            background: #fff;
            border-radius: 10px;
            border: 1px solid #ccc;
            .image-steps {
              width: 100%;
              height: 100px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }
            p {
              text-align: center;
              font-weight: bold;
              padding-top: 15px;
              min-height: 66px;
            }
          }
        }
      }
      .list-wrapper-custom-left {
        .ant-col {
          &:nth-child(1) {
            .card-wrapper {
              .image-steps {
                background-image: url("../../assets/img/add-user.png");
              }
            }
          }
          &:nth-child(2) {
            .card-wrapper {
              .image-steps {
                background-image: url("../../assets/img/online-store.png");
              }
            }
          }
          &:nth-child(3) {
            .card-wrapper {
              .image-steps {
                background-image: url("../../assets/img/hand-shake.png");
              }
            }
          }
        }
      }
      .list-wrapper-custom-right {
        .ant-col {
          &:nth-child(1) {
            .card-wrapper {
              .image-steps {
                background-image: url("../../assets/img/add-user.png");
              }
            }
          }
          &:nth-child(2) {
            .card-wrapper {
              .image-steps {
                background-image: url("../../assets/img/search.png");
              }
            }
          }
          &:nth-child(3) {
            .card-wrapper {
              .image-steps {
                background-image: url("../../assets/img/milling-machine.png");
              }
            }
          }
        }
      }
    }
  }
  .newsletter-wrapper {

  }
  .about-us {
    padding: 50px 0;
    .about-us-logo {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      img {
        max-width: 250px;
      }
    }
    .about-us-description {
      p {
        text-align: center;
      }
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;