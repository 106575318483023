.card-container {
  border: 1px solid var(--color-border);
  border-radius: 10px;
  background: var(--color-white);
  padding: 10px;
  &:hover {
    opacity: .9;
  }
  .image-wrapper {
    max-height: 220px;
    height: 220px;
    img {
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .card-content-wrapper {
    position: relative;
    padding-top: 15px;
    .card-title {
      margin: 0;
      font-size: 16px;
      max-height: 40px;
      height: 40px;
      line-height: 20px;
    }
    .card-location {
      padding-top: 10px;
      font-size: 12px;
    }
    .card-price {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 500px){
        padding: 7px 0;
        .ant-statistic-content {
          font-size: 16px;
        }
      }
      .with-vat {
        font-size: 12px;
        color: var(--color-label);
        padding-left: 10px;
      }
    }
    .card-without-price {
      font-weight: bold;
      background: rgba(65, 66, 71, 0.08);
      display: inline-block;
      padding: 4px 10px;
      border-radius: 10px;
      margin-top: 5px !important;
      @media only screen and (max-width: 500px){
        font-size: 14px;
        margin-bottom: 5px !important ;
      }
    }
  }
}
.card-edit-container {
  &:hover {
    opacity: .9;
  }
}
.card-container-grid {
  &>.ant-row {
    height: 250px;
    @media only screen and (max-width: 992px){
      height: inherit;
      .image-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
        img {
          max-height: 275px;
          height: 275px;
        }
      }
      .card-content-wrapper {
        padding-left: 0;
        .card-title {
          max-height: 50px;
          height: 50px;
          line-height: 25px;
        }
        .card-producer {
          margin-top: 5px;
        }
      }
    }

    .image-wrapper {
      max-height: 100%;
      height: 100%;
    }
  }
  .image-wrapper {
    &>a {
      display: block;
      height: 100%;
      .ant-image{
        max-height: 250px;
        height: 100%;
        img {
          object-fit: contain;
          border-radius: 10px;
        }
      }
    }

  }
  .card-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 15px;
    .card-title {
     font-size: 22px;
    }
    .card-producer {
      font-size: 12px;
      font-weight: bold;
      span {
        color: var(--color-primary);
        padding-right: 5px;
      }
    }
    .card-description {
      margin-top: 15px;
    }
    .bottom-info {
      position: relative;
      .card-price {
        font-size: 22px;
      }
    }
  }
}

.card-container-mobile {
  @media only screen and (max-width: 876px){
    padding: 0;
    position: relative;
    &>a {
      .image-wrapper {
        max-height: 160px;
        height: 160px;
        img {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    .card-content-wrapper {
      position: initial;
      padding-left: 10px;
      padding-right: 10px;
      .button-fav {
        right: 6px;
        top: 5px;
      }
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;