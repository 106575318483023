.button-main {
  position: absolute;
  z-index: 222;
  width: 50px;
  height: 50px;
}

.button-fav {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &.fill {
    background-image: url('../../../assets/icons/favFill.svg');
  }
  &.empty {
    background-image: url('../../../assets/icons/fav.svg');
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;