.product-single-wrapper {
    .left-col-wrapper {
        .product-single-slider {
            .slide-wrapper {
                background-color: var(--color-white);
                border-radius: 10px;
                img {
                    width: 100%;
                    height: 500px;
                    object-fit: contain;
                    border-radius: 10px;
                    padding: 15px;
                }
            }
            .ant-carousel {
                .slick-arrow {
                    width: 40px;
                    height: 40px;
                    background-size: 12px !important;
                    background-position: 12px center !important;
                }
                .slick-prev {
                    left: 25px;
                    z-index: 2;
                }
                .slick-next {
                    right: 25px;
                    z-index: 2;
                }
            }
        }
        .product-single-location {
            padding: 15px;
            background-color: var(--color-white);
            border-radius: 10px;
            @media only screen and (max-width: 876px){
                .title-main {
                    font-size: 22px;
                }
            }
            .icon-loc {
                display: flex;
                img {
                    max-width: 15px;
                    margin-right: 10px;
                }
            }
            .price {
                .card-price {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    .with-vat {
                        padding-left: 12px;
                        font-size: 12px;
                        color: var(--color-label);
                        line-height: 1;
                    }
                }
                .card-without-price {
                    font-weight: bold;
                    background: rgba(65, 66, 71, 0.08);
                    display: inline-block;
                    padding: 4px 10px;
                    border-radius: 10px;
                    margin-top: 5px !important;
                }
            }
        }
        .product-single-description {
            padding: 15px;
            background-color: var(--color-white);
            border-radius: 10px;
            p {
                white-space: pre-line;
            }
        }
    }
    .right-col-wrapper {

        .product-single-user {
            .username {
                padding-bottom: 10px;
            }
        }
        .product-single-spec {
            margin-bottom: 45px;
        }
        .ant-card-head {
            color: var(--color-secondary);
            min-height: 40px;
            .ant-card-head-wrapper {
                .ant-card-head-title {
                    padding: 6px 0;
                    font-weight: bold;
                }
            }
        }
        .card-bg {
            background-color: var(--color-white);
            border-radius: 10px;
            padding: 15px;
        }
    }
    .item-info {
        display: flex;
        padding-bottom: 5px;
        label {
            min-width: 130px;
        }
    }
}


@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;