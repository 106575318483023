.prices-wrapper {
  &> .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
  }
  .ant-table-content {
    .ant-table-thead {
      tr {
        th {
          font-weight: bold;
        }
      }
    }
    .ant-table-tbody {
      tr {
        .ant-table-cell {
          &:nth-child(1) {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .prices-table-wrapper {
    .ant-table-tbody {
      tr {
        .ant-table-cell {
          &:nth-child(3) {
            color: var(--color-primary);
            font-size: 20px;
            font-weight: bold;
          }
        }

      }
    }
  }

}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;