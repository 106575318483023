.filters-wrapper {
  .return-prev-category {
    .return-icon-category {
      a {
        position: relative;
        display: block;
        padding-left: 22px;
        &:before {
          content:'';
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: 16px;
          background-position: center left;
          background-repeat: no-repeat;
          background-image: url('../../assets/icons/return.svg');
        }
      }
    }
  }
}

.search-result-wrapper {
  .hide-input-search {
    display: none !important;
  }

  .filters-wrapper {
    .mobile-ham {
      display: flex;
      align-items: center;
      border: none;
      cursor: pointer;

      position: fixed;
      left: 0;
      background: var(--color-primary);
      padding: 10px;
      z-index: 999;
      bottom: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      p {
        padding-right: 10px;
        font-weight: bold;
      }
      img  {
        width: 15px;
      }
    }
    .ant-btn-primary {
      color: var(--color-secondary);
    }

  }

  .result-wrapper {
    .custom-breadcrumbs {
      .title-main {
        width: 100%;
        line-height: 25px;
        height: 35px;
        padding-bottom: 5px;
        margin-bottom: 0;
      }
      .breadcrumb-home-text {
        font-size: 12px;
        margin-top: 5px;
        padding: 3px 0 3px 10px;
        font-weight: bold;
        span {
          padding: 0 5px;
          color: var(--color-secondary);
        }
      }
      .ais-Breadcrumb-list {
        display: flex;
        list-style: none;
        font-size: 12px;
        margin-top: 5px;
        padding: 3px 10px 3px 0;
        border-radius: 3px;
        .ais-Breadcrumb-link {
          font-weight: bold;
        }
        .ais-Breadcrumb-separator {
          padding: 0 5px;
        }
      }
    }
    .custom-hits {
      list-style: none;
      padding: 0;

      .no-result-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        p {
          padding-bottom: 15px;
        }
      }
      .button-wrapper-more {
        width: 100%;
        display: flex;
        justify-content: center;
        button {
          background: var(--color-white);
          border: 1px solid var(--color-primary);
          border-radius: 10px;
          cursor: pointer;
          padding: 5px 15px;
          font-weight: bold;
        }
      }
      .show-more-disabled {
        display: none !important;
      }

      .custom-desc {
        flex: 1 0 100%;
      }
    }

    .custom-desc {
      margin-top: 35px;
      padding: 30px;
      background-color: var(--color-white);
      border: 1px solid var(--color-border);
      border-radius: 10px;
      @media only screen and (max-width: 876px){
        padding: 15px;
      }
    }
  }

  .ais-Pagination {
    .ais-Pagination-list {
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: center;
      .ais-Pagination-item {
        padding: 0 5px;
      }
    }
  }

}


@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;