.slick-slider {
  @media only screen and (max-width: 876px){
    .slick-prev {
      left: -5px;
      z-index: 3;
    }
    .slick-next {
      right: 0;
      z-index: 3;
    }
  }
  .slick-dots {
    li {
      width: 15px;
      margin-right: 10px;
      margin-left: 10px;
      button {
        background: #D9D9D9;
        opacity: 1;
        height: 15px;
        border-radius: 50%;
      }
    }
    li.slick-active {
      width: 45px;
      button {
        background: var(--color-primary);
        border-radius: 10px ;
      }
    }
  }
  .slick-dots-bottom {
    bottom: 20px;
  }
  .slick-arrow {
    background-color: var(--color-primary) !important;
    background-image: url("../assets/icons/arr.svg") !important;
    background-size: 8px !important;
    background-repeat: no-repeat !important;
    background-position: 5px center !important;
    border-radius: 50% !important;
  }
  .slick-next {
    transform: rotate(180deg);
  }
}





//mobile
.ant-drawer-body {
  .links {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    a {
      margin-bottom: 15px;
    }
  }
  ul.ant-menu {
    border-right: none;
    .ant-menu-submenu-title {
      padding-left: 0 !important;
    }
    ul.ant-menu-sub {
      .ant-menu-item-only-child {
        padding-left: 24px !important;
      }
    }
  }
}
//mobile filters
.aff-wrapper {
  margin-right: 15px;
  background: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  border: 1px solid var(--color-border);
  .filters-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border);
    margin-bottom: 20px;
    padding-bottom: 9px;
    &--text {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .ais-ClearRefinements {
      button {
        position: relative;
        background: none;
        border: none;
        cursor: pointer;
        padding-left: 15px;
        font-size: 12px;
        line-height: 12px;
        opacity: .5;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 10px;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../assets/icons/reset.svg');
        }
      }
    }
  }
  .ais-HierarchicalMenu-list {
    padding: 0;
    list-style: none;

    &--child {
      padding-left: 10px;
    }
    &>.ais-HierarchicalMenu-item--parent {
      &>a.ais-HierarchicalMenu-link {
        display: none;
        pointer-events: none;
        cursor: default;
      }
    }
    .ais-HierarchicalMenu-link--selected {
      //color: var(--color-primary);
    }
    .ais-HierarchicalMenu-list--child {
      .ais-HierarchicalMenu-item {
        a {
          .ais-HierarchicalMenu-label {
            color: var(--color-label);
          }
        }
      }
    }
    a {
      color: var(--color-secondary);
    }
  }

  .ais-RefinementList-list {
    padding: 0;
    list-style: none;

    .ais-RefinementList-item {
      .ais-RefinementList-label {
        cursor: pointer;
        .ais-RefinementList-labelText {
          padding-left: 5px;
        }
      }

    }
  }
  .ais-HierarchicalMenu-count,
  .ais-RefinementList-count {
    font-size: 10px;
    padding: 2px 8px;
    background-color: rgba(65, 66, 71, 0.08);
    border-radius: 5px;
    font-weight: bold;
  }
  .ais-Panel-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .ais-Panel-body {
    padding-left: 5px;
  }
}

@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;