.not-found-wrapper {
  .not-found-info-wrapper {
    background-color: var(--color-white);
    border-radius: 10px;
    .info-container {
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        max-width: 300px;
      }
      .main-title {
        text-align: center;
      }
      .desc {
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;