.faq-wrapper {
  scroll-behavior: smooth;
  &> .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .faq-info-wrapper {
    h2 {
      font-size: 20px;
      font-weight: bold;
      padding-top: 20px;
    }
  }
  .faq-menu-wrapper {
    .link-wrapper {
      border-left: 2px solid #ccc;
      padding-left: 10px;
      a {
        color: var(--color-secondary);
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;