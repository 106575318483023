.blog-wrapper {
  .blog-card {
    display: flex;
    background-color: var(--color-white);
    border-radius: 10px;
    margin-bottom: 16px;
    .image-wrapper {
      img {
        height: 250px;
        object-fit: cover;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
    }
    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 15px 20px 15px;
      height: 100%;
      .card-title {
        margin-bottom: 0;
      }
      .card-date-container {
        display: flex;
        align-items: center;
        img {
          max-width: 20px;
        }
        .card-date {
          font-size: 12px;
          color: var(--color-label) !important;
          padding-left: 8px;
        }
      }

    }
  }
  .blog-nav-wrapper {
    background-color: var(--color-white);
    padding: 10px;
    border-radius: 10px;
    .blog-nav-link {
      a {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

.blog-single-wrapper {
  .blog-single {
    background-color: var(--color-white);
    border-radius: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
    .image-wrapper {
      padding-bottom: 20px;
      img {
        border-radius: 10px;
      }
    }
    .card-date-container {
      display: flex;
      align-items: center;
      padding-bottom: 25px;
      img {
        max-width: 20px;
      }
      .card-date {
        font-size: 12px;
        color: var(--color-label) !important;
        padding-left: 8px;
      }
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;