.add-product-wrapper {
  position: relative;
  .overlay-loading {
    position: absolute;
    background: rgba(255,255,255,.7);
    left: 0;
    top: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-spin-dot {
      font-size: 50px;
    }
  }
  .overlay-add-product {
    //position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    //background: #000;
    z-index: 1;
    background-image: url("../../../assets/img/addProd.png");
    background-size: cover;
    p {
      padding-bottom: 25px;
      font-size: 25px;
      font-weight: bold;
    }
    //background: linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,1) 85%);
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;