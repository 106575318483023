.authForm-container {
  margin: 0 auto;
  max-width: 600px;
  padding-top: 35px;
  padding-bottom: 35px;
  .route-wrapper {
    display: flex;
    justify-content: space-between;
    .link-wrapper {
      width: 100%;
      text-align: center;
      padding-bottom: 30px;
      .link {
        position: relative;
        display: inline-block;
        width: 100%;
        font-size: 24px;
        font-weight: 600;
        color: #333 !important;
        padding-bottom: 10px;
        &:after {
          content: '';
          position: absolute;
          background-color: #DDDDDD;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 0;
        }
      }
      .link.active {
        &:after {
          background-color: var(--color-primary);
          height: 2px;
        }
      }
    }
  }
  .form-wrapper {
    background-color: #fff;
    padding: 20px;
  }
  .agreement-wrapper {
    .ant-form-item {
      margin: 0;
      .ant-checkbox-wrapper {
        font-size: 12px;
      }
      .ant-form-item-explain {
        line-height: 1;
      }
    }
    .agreement-collapse {
      display: flex;
      font-size: 12px;
      .text {
        padding-left: 8px;
        padding-top: 5px;
      }
      .arr-down {
        display: flex;
        align-items: center;
        img {
          width: 10px;
          transform: rotate(-90deg);
          height: 10px;
        }
      }
    }
  }
  .ant-space {
    width: 100%;
    margin-top: 25px;
    justify-content: center;
  }
  .custom-item-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    span {
      font-size: 16px;
      font-weight: 600;
      margin-right: 10px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;