.contact-wrapper {
  @media only screen and (max-width: 992px){
    .contact-mobile {
      padding-left: 0 !important;
      padding-right: 0 !important;
      h3 {
        font-size: 22px;
      }
      .contact-form-wrapper {
        background: var(--color-white);
      }
    }
  }
  .contact-title {
    font-size: 30px;
    font-weight: bold;
  }
  .contact-form-wrapper {
    background: var(--color-bg);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .contact-info-wrapper {
    .contact-title {
      padding-top: 10px;
    }
    .contact-icons-wrapper {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      a {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        span {
          padding-left: 15px;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
  }

}

@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;