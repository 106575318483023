.status-wrapper {
  .thx-text-container {
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
    .thx-amount {
      font-size: 45px;
      font-weight: bold;
    }
    .thx-text {

    }
  }
  .thx-btn {

  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;