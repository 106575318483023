@import 'reset-ant.css';

:root {
    --color-primary: #FED700;
    --color-hover-primary: rgba(254, 215, 0, .5);
    --color-secondary: #333333;
    --color-bg: #F5F5F5;
    --color-bg-seconary: #E6E6E6;
    --color-border: #DDDDDD;
    --color-white: #FFFFFF;
    --color-label: #9B9B9B;
}

* {
    font-family: 'Lato', sans-serif !important;
}

body #root{
    font-size: 16px;
    background-color: var(--color-bg);
}
body #root .main-content .container{
    /*background-color: var(--color-bg);*/
}
header .container .header-wrapper,
footer {
    background-color: var(--color-white);
}
.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
    position: relative;
}
.container-inner {
    padding-left: 15px;
    padding-right: 15px;
}
h1 {
    font-size: 28px;
}
.title-main {
    position: relative;
    margin-bottom: 25px;
    border-bottom: 1px solid var(--color-border);
}
.title-main:before {
    position: absolute;
    content: '';
    background-color: var(--color-primary);
    bottom: -2px;
    width: 40px;
    height: 2px;
}
.title-main {

}

.m-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.spin-main {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.container-white {
    background: var(--color-white);
    border: 1px solid var(--color-border);
    margin-top: 25px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 20px;
    border-radius: 10px;
}
img {
    width: 100%;
}

.ant-col-5-custom {
    display: block;
    flex: 0 0 20%;
    max-width: 20%;
}
.page-wrapper {
    padding: 25px 15px;
}
.margin-lr {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}
@media only screen and (max-width: 992px){
    .mobile-hide {
        display: none !important;
    }
    /*.container-inner {*/
    /*    padding-top: 15px;*/
    /*}*/
}
@media only screen and (min-width: 993px){
    .desktop-hide {
        display: none !important;
    }
    /*.main-content {*/
    /*    padding: 40px 0;*/
    /*}*/
}