.footer-main {
  padding-top: 35px;
  padding-bottom: 35px;
  .footer-logo-wrapper {
    &>img {
      max-width: 220px;
      margin-bottom: 30px;
    }
  }
  .support-wrapper {
    display: flex;
    align-items: flex-start;
    img {
      width: 60px;
    }
    .support-info {
      padding-left: 15px;
      a {
        font-weight: bold;
      }
      .fb-wrapper {
        margin-top: 15px;
        &>a {
          display: flex;
          img {
            max-width: 25px;
            margin-left: 10px;
          }
        }
      }
    }
  }

    .links {
      display: flex;
      flex-direction: column;
      a {
        color: var(--color-secondary);
        &:hover {
          color: var(--color-primary);
        }
      }
    }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;