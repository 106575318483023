#root p {
    margin: 0;
    color: var(--color-secondary);
}

#root .btn-wrapper-center {
    width: 100%;
    justify-content: center;
}
#root .btn-wrapper-right {
    width: 100%;
    justify-content: flex-end;
}
#root .ant-btn {
    /*background: var(--color-primary);*/
    color: #333;
    font-size: 16px;
    height: 40px;
    transition: .3s;
    border-radius: 25px;
}

#root .btn-ad button {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: var(--color-white);
}
#root .btn-ad button:hover {
    background-color: var(--color-secondary);
    opacity: .8;
}
#root .ant-btn:hover {
    background: var(--color-hover-primary);
}

#root .ant-form-item-explain-error {
    font-size: 11px;
}

#root .ant-input,
#root .ant-input-affix-wrapper,
#root .ant-select-selector{
    border-radius: 20px;
}
#root .ant-select-selector{
    height: 43px;
    padding: 6px 20px;
}

#root .ant-input-password .ant-form-item-feedback-icon {
    display: none;
}

#root .ant-row {
    width: 100%;
}
.ant-spin {
    width: 100%;
    height: 100%;
}
.container-inner .ant-form-item-label > label {
    font-size: 14px;
    color: var(--color-label);
}