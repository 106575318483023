.header-wrapper {
    .topbar-container {
        padding: 7px 0;
        border-bottom: 1px solid var(--color-border);
        .header-icons-custom {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .icons {
                display: flex;
                justify-content: flex-end;
                .icon-wrapper {
                    margin-left: 16px;
                    &:nth-child(1),
                    &:nth-child(2) {
                        padding-right: 16px;
                        border-right: 2px solid var(--color-border);
                    }
                    a {
                        color: #333;
                        display: block;
                    }
                    @media only screen and (max-width: 876px){
                        margin-left: 7px;
                        &:nth-child(1),
                        &:nth-child(2) {
                            padding-right: 0;
                            border-right: 2px solid var(--color-border);
                        }
                    }
                }
            }
        }

        //@media only screen and (max-width: 876px){
        //    .top-text {
        //        display: none;
        //    }
        //    .icon-wrapper {
        //        span {
        //            display: none;
        //        }
        //    }
        //}
    }
    .header-container {
        //padding: 10px 0 25px 0;
        .header-container-middle {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .logo-wrapper {
            img {
                max-width: 220px;
            }
        }
        .search-wrapper {
            input {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border: 2px solid var(--color-primary);
            }
            .ant-input-group-addon {
                border-radius: 25px;
                button {
                    border: 2px solid var(--color-primary);
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    height: 45px !important;
                    width: 60px;
                    background: var(--color-primary);
                }
            }
        }
        .mobile {
            padding: 10px;
            background-color: var(--color-white);
            .mobile-ham {
                width: 45px;
                height: 45px;
                background: transparent;
                border: 2px solid var(--color-primary);
                border-radius: 50%;
                img {
                    height: 23px;
                    width: 23px;
                }
            }
        }
    }
    .nav-container {
        .category-list-wrapper {
            width: 100%;
            z-index: 1;
            .cat-list {
                width: 100%;
                background-color: var(--color-primary);
                padding: 8px 10px 8px 16px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                p {
                    font-weight: 600;
                }
            }
        }
        .link-list-wrapper {
            max-height: 41px;
            z-index: 1;
            .link-container {
                padding: 8px 20px 8px 50px;
                a {
                    font-weight: 600;
                    font-size: 16px;
                    margin-right: 35px;
                    color: var(--color-secondary);

                }
            }
        }
    }
}

.header-wrapper.pages {
    .nav-container {
        .custom-row {
            background-color: var(--color-primary);
        }
        .category-wrapper {

            .button-all-cat {
                background: transparent;
                border: none;
                cursor: pointer;
            }

        }
    }
}
.header-wrapper.home-page {
    .nav-container {
        ul.ant-menu {
            &>li {
                display: none;
            }
            &>li:nth-child(-n+8) {
                display: list-item;
                .ant-menu-submenu-title {
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
        .custom-row {
            position: absolute;
            width: 100%;
            background-color: var(--color-white);
            ul.ant-menu {
                .ant-menu-title-content {
                    a {
                        color: var(--color-secondary);
                    }
                }
                .ant-menu-submenu-title {
                    &:hover {
                        .ant-menu-title-content {
                            a {
                                color: inherit;
                            }
                        }
                    }
                }
            }
            .cat-list {
                background-color: var(--color-white);
                border-radius: 0;
            }
            .cat-all {
                background-color: var(--color-white);
                font-weight: 700;
                padding: 7px 16px 12px 16px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                color: inherit;
                display: block;
            }

        }
        .link-container {
            background-color: var(--color-white);
            a {
                font-weight: 600;
                font-size: 16px;
                margin-right: 35px;
                color: var(--color-secondary);
                &:hover {
                    color: var(--color-primary);
                }
            }
        }

    }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;