.acc-nav-wrapper {
  @media only screen and (max-width: 876px){
    display: none;
  }
  background: var(--color-white);
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid var(--color-border);
  .link {
    position: relative;
    padding: 5px 20px;
    margin-right: 15px;
    display: inline-block;
    color: var(--color-secondary);
    opacity: .7;
    transition: .2s;
    &:hover {
      opacity: 1;
    }

    &.active {
      color: var(--color-secondary);
      opacity: 1;
      font-weight: 600;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--color-secondary);
        bottom: 0;
        left: 0;
      }
    }
  }
}
.acc-content-wrapper {
  background: var(--color-white);
  border: 1px solid var(--color-border);
  margin-top: 25px;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  h3 {
    font-weight: 600;
    margin-bottom: 15px;
  }
  .ant-form-item-label {
    label {
      font-size: 14px;
      color: var(--color-label);
    }
  }
}
.product-list-ad {
  .image-wrapper {
    img {
      object-fit: contain !important;
    }
  }
  .edit-prod {
    display: flex;
    padding-top: 10px;
    border-top: 1px solid var(--color-border);
    &>a {
      display: block;
      width: 100%;
      .icon-wrapper {
        img {
          height: 25px;
        }
      }
    }
    button {
      display: block;
      width: 100%;
      background: transparent;
      border: none;
      cursor: pointer;
      img {
        height: 25px;
      }
    }
  }
}
.buy-packages-wrapper {
  padding: 0 !important;
  @media only screen and (max-width: 876px){
    .buy-cards {
      flex-direction: column;
      align-items: center;
      .buy-card {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
    }
    .payment-promo-info {
      p {
        font-size: 18px !important;
      }
    }
    .danger-info {
      text-align: center;
    }
  }
  .buy-cards {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 25px;

    .ant-radio-button-wrapper-checked {
      background: var(--color-white) !important;
      .main-price {
        color: var(--color-primary) !important;
      }
    }
    .ant-radio-button-wrapper {
      &:nth-child(1) {
        .card-text {
          min-height: 250px;
        }
      }
      &:nth-child(2) {
        .card-text {
          min-height: 350px;
        }
      }
      &:nth-child(3){
        .card-text {
          min-height: 350px;
        }
      }
    }
    .buy-card {
      // box-sizing: border-box;
      width: 250px;
      padding: 0;
      &:nth-child(2) {
        margin-left: 30px;
        margin-right: 30px;
      }
      .card-text {
        text-align: center;
        position: relative;
        //display: flex;
        //flex-direction: column;
        //justify-content: space-between;
        .card-title {
          background: var(--color-primary);
          font-weight: bold;
          border-top-left-radius: 10px;
          border-top-right-radius: 8px;
          //color: var(--color-white) !important;
        }
        .price-container {
          //display: flex;
          //flex-direction: column;
          //justify-content: center;
          //min-height: 115px;
          padding: 25px 0;
          .main-price {
            font-size: 40px;
            padding: 30px 0;
          }
          .price-text {
            font-size: 14px;
            line-height: 1.3;
            .top-label {
              background-color: var(--color-bg-seconary);
              padding: 5px 10px;
              border-radius: 5px;
            }
          }
          .price-text-above {
            font-size: 18px;
          }
        }
        .card-info {
          position: absolute;
          bottom: 0;
          font-size: 12px;
          line-height: 1.2;
          padding: 10px;
          border-top: 1px solid var(--color-border);
        }
      }
    }

    .ant-radio-button-wrapper {
      border: 1px solid #ccc;
      border-radius: 10px;
      height: 100%;
      &:before {
        content: none;
      }
    }
  }
  .card-promo-home {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--color-border);
  }
  .payment-promo-info {
    max-width: 635px;
    margin: 0 auto;
    padding-bottom: 30px;
    text-align: center;
    p {
      font-size: 20px;
      line-height: 1.3;
    }
  }
  .custom-item-wrapper {
    max-width: 635px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    //align-items: center;
    padding-bottom: 15px;
    .ant-checkbox-wrapper {
      align-items: flex-start;
      .ant-checkbox-inner {
        margin-right: 0;

      }
      span {
        font-size: 12px;
        font-weight: inherit;
      }
    }
    .danger-info {

      font-size: 14px;
    }
  }
}
.add-to-fav-wrapper {
  .content-wrapper {
    .ant-typography {
      min-height: 50px;
    }
  }
  .button-fav {
    top: 10px;
    right: 20px;
  }
}


.modal-custom-form {
  .ant-form-item {
    margin-bottom: 5px !important;
    .ant-select-selector {

    }
    .ant-form-item-label {
      padding-bottom: 2px !important;
      &>label {
        font-size: 12px !important;
      }
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;