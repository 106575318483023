.state-products-wrapper {
  .products-list {
    margin-top: 25px;
    .card-wrapper {
      background: #fff;
      border: 1px solid #ccc;
      padding-bottom: 15px;
      border-radius: 10px;
      &>a {
        display: block;
        position: relative;
        .product-img {
          height: 200px;
          object-fit: contain;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        .product-logo {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 100px;
          border-top-left-radius: 10px;
        }
      }
      .info {
        padding: 0 12px;
        .ant-typography {
          min-height: 50px;
        }
      }
    }
  }
}
@primary-color: #FED700;@link-color: #FED700;@success-color: #0DD078;@font-size-base: 16px;@border-radius-base: 10px;@btn-padding-horizontal-base: 35px;@btn-height-base: 40px;@input-padding-horizontal-base: 20px;@input-padding-vertical-base: 8px;